import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AuthContext = createContext({
    authState: { email: "", token: "", user_id: "", role: "" },
    login: async (email, password) => {},
    logout: async () => {}
});

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate(); 
    
    const [authState, setAuthState] = useState(() => {
        const currentTime = new Date().getTime();
        const storedTime = localStorage.getItem("loginTime");
        const isExpired = storedTime && (currentTime - parseInt(storedTime, 10) > 86400000); 

        if (isExpired) {
            localStorage.removeItem("email");
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("loginTime");
            localStorage.removeItem("role");
            return { email: "", token: "", user_id: "", role: "" };
        } else {
            return {
                email: localStorage.getItem("email") || "",
                token: localStorage.getItem("token") || "",
                user_id: localStorage.getItem("user_id") || "",
                role: localStorage.getItem("role") || "",
            };
        }
    });

    useEffect(() => {
        if (authState.token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${authState.token}`;
        } else {
            delete axios.defaults.headers.common['Authorization'];
            navigate("/login"); 
        }
    }, [authState.token, navigate]); 

    const login = async (email, password) => {
        try {
            const response = await axios.post("https://wd79p.com/backend/public/api/login", 
                { email, password },
                {
                    headers: {
                        'Authorization': `Bearer ${authState.token}`
                    }
                }
            );
    
            if (response.status === 200 && response.data.token) {
                const { token, user_id } = response.data;
                
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                
                const userDetailsResponse = await axios.get(`https://wd79p.com/backend/public/api/users/${user_id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                const { role } = userDetailsResponse.data;
    
                const userData = { email, token, user_id, role };
                setAuthState(userData);
    
                localStorage.setItem("email", email);
                localStorage.setItem("token", token);
                localStorage.setItem("user_id", user_id);
                localStorage.setItem("role", role);
                localStorage.setItem("loginTime", new Date().getTime().toString());
    
                
    
                return { success: true, role };
            } else {
                return { success: false, message: "Invalid email or password." };
            }
        } catch (error) {
            console.error("Login failed:", error);
            return { success: false, message: "Login failed. Please try again." };
        }
    };
    

    const logout = async () => {
        const token = localStorage.getItem("token");
    
        if (!token) {
            console.error('No token found');
            navigate("/login"); 
            return { success: false, message: "No token found." };
        }
    
        try {
            
            const clockOutResponse = await axios.post(
                "https://wd79p.com/backend/public/api/clock-out",
                {
                    user_id: authState.user_id,  
                },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`  
                    }
                }
            );
    
            if (clockOutResponse.status !== 200) {
                console.error("Clock-out failed:", clockOutResponse);
                return { success: false, message: "Failed to clock out. Please try again." };
            }
    
            
            const response = await axios.post(
                "https://wd79p.com/backend/public/api/logout",
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                }
            );
    
            if (response.status === 200) {
                
                setAuthState({ email: "", token: "", user_id: "", role: "" });
                localStorage.removeItem("email");
                localStorage.removeItem("token");
                localStorage.removeItem("user_id");
                localStorage.removeItem("role");
                localStorage.removeItem("loginTime");
                localStorage.removeItem("isClockedIn");  
                localStorage.removeItem("clockedInTime"); 
                delete axios.defaults.headers.common['Authorization'];
    
                navigate("/login"); 
    
                return { success: true };
            } else {
                console.error("Logout failed");
                return { success: false, message: "Logout failed. Please try again." };
            }
        } catch (error) {
            console.error("Logout/Clock-out failed:", error);
            return { success: false, message: "Logout failed. Please try again." };
        }
    };
    
    

    const value = {
        authState,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};


export default AuthContext;
