import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../AuthContext";
import { Applogo, Avatar_02 } from "../../Routes/ImagePath";
import { FaRegBell } from "react-icons/fa";
import Pusher from "pusher-js";
import WhatsAppChatModal from "../../components/modelpopup/WhatsApp/WhatsAppChatModal";
import { Button } from "antd";
import { FaWhatsapp } from "react-icons/fa";
import "../layout/notif.css";

const Header = () => {
  const { logout, authState } = useAuth();
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [userNames, setUserNames] = useState({});
  const [dropdown, setDropdown] = useState({
    notification: false,
    profile: false,
  });
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const fetchUserData = async () => {
    try {
      const response = await axios.get(`https://wd79p.com/backend/public/api/users/${authState.user_id}`);
      setFirstName(response.data);
      setLastName(response.data);
      
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const fetchUserName = async (userId) => {
    try {
      const response = await axios.get(`https://wd79p.com/backend/public/api/users/${userId}`);
      return `${response.data.first_name} ${response.data.last_name}`;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return ""; 
      }
      console.error("An error occurred:", error); 
      return ""; 
    }
  };

  const markNotificationAsRead = async (id) => {
    try {
      await axios.post(`https://wd79p.com/backend/public/api/activities/${id}/mark-read`);
      setNotifications((prev) =>
        prev.map((notification) => {
          if (notification.id === id) {
            return { ...notification, notif_status: 1 };
          }
          return notification;
        })
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  const handleNotificationClick = async (id, link) => {
    await markNotificationAsRead(id);
    navigate(link);
  };

  const formatNotification = (notification) => {
    const { id, user_id, action, subject_type, created_at, subject_id } = notification;
    const formattedDate = new Date(created_at).toLocaleString();
    const userName = userNames[user_id] || "Unknown User";

    const subjectType = subject_type.split("\\").pop();

    let link = "#";
    switch (subjectType) {
      case "Ticket":
        link = `/ticket-details/${subject_id}`;
        break;
      case "User":
        link = `/users-details/${subject_id}`;
        break;
      case "Merge":
        link = `/ticket-details/${subject_id}`;
        break;
      case "Contact":
        link = `/contact-details/${subject_id}`;
        break;
      default:
        break;
    }

    return {
      id,
      message: `${userName} ${action} on ${subjectType} ${subject_id} at ${formattedDate}`,
      link,
    };
  };

  useEffect(() => {
    if (authState.user_id) {
      fetchUserData();
    }
  }, [authState.user_id]);

  useEffect(() => {
    const pusher = new Pusher("f4dc303669ceaa4de254", {
      cluster: "ap1",
    });

    const channel = pusher.subscribe("notifications");

    channel.bind("new-notification", async (data) => {
      setNotifications((prev) => [data, ...prev]);
      if (data.notif_status === 0) {
        setUnreadNotifications((prev) => [data, ...prev]);
      }

      const userName = await fetchUserName(data.user_id);
      setUserNames((prev) => ({
        ...prev,
        [data.user_id]: userName,
      }));
    });

    return () => {
      pusher.unsubscribe("notifications");
    };
  }, []);

  const handleSidebar = () => {
    document.body.classList.toggle("mini-sidebar");
  };

  const onMenuClick = () => {
    document.body.classList.toggle("slide-nav");
  };

  const toggleDropdown = async (type) => {
    setDropdown((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));

    if (type === "notification" && !dropdown.notification) {
      try {
        setUnreadNotifications([]);
        setNotifications((prev) =>
          prev.map((notification) => ({
            ...notification,
            notif_status: 1,
          }))
        );
      } catch (error) {
        console.error("Error updating notification status:", error);
      }
    }
  };

  const location = useLocation();
  const pathname = location.pathname;

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem("token");
      navigate("/login");
    } catch (error) {
      console.error("Logout or clock out failed:", error);
    }
  };

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div className="header-left">
        <Link to="/employee-dashboard" className="logo">
          <img src={Applogo} width={75} height={75} alt="logo" />
        </Link>
        <Link to="/employee-dashboard" className="logo2">
          <img src={Applogo} width={40} height={40} alt="logo" />
        </Link>
      </div>
      {/* /Logo */}
      <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks") || pathname.includes("compose") ? "none" : "",
        }}
        onClick={handleSidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
      {/* Header Title */}
      <div className="page-title-box">
        <h3>SparkCRM</h3>
      </div>
      {/* /Header Title */}
      <Link id="mobile_btn" className="mobile_btn" to="#" onClick={onMenuClick}>
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu">
      {/* WhatsApp Button */}
      <div>
        <Button
          className="nav-item"
          onClick={showModal}
          style={{ backgroundColor: "#45C554", borderColor: "#45C554", color: "#ffffff" }}
          icon={<FaWhatsapp style={{ fontSize: '20px', color: '#ffffff' }} />}
        >
        </Button>
          <WhatsAppChatModal visible={isModalVisible} onClose={handleClose} />
      </div>
        {/* Notifications */}
        {/* <li className="nav-item dropdown">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={() => toggleDropdown("notification")}
          >
            <i>
              <FaRegBell />
            </i>{" "}
            <span className="badge badge-pill">{unreadNotifications.length}</span>
          </Link>
          <div className={`dropdown-menu dropdown-menu-end notifications ${dropdown.notification ? "show" : ""}`}>
            <div className="topnav-dropdown-header">
              <span className="notification-title">Notifications</span>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                {notifications.map((notification, index) => {
                  const { id, message, link } = formatNotification(notification);
                  return (
                    <li className={`notification-message ${notification.notif_status === 0 ? "unread" : "read"}`} key={index}>
                      <Link to="#" onClick={() => handleNotificationClick(id, link)}>
                        <div className="media d-flex">
                          <div className="media-body">
                            <p className="noti-details">{message}</p>
                            <p className="noti-time">
                              <span className="notification-time">{new Date(notification.created_at).toLocaleTimeString()}</span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </li> */}
        {/* /Notifications */}
        {/* User Profile */}
        <li className="nav-item dropdown has-arrow main-drop">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={() => toggleDropdown("profile")}
          >
            <span>{firstName.first_name} {lastName.last_name}</span>
          </Link>
          <div className={`dropdown-menu ${dropdown.profile ? "show" : ""}`}>
            <Link className="dropdown-item" to="/profile">
              My Profile
            </Link>
            <Link className="dropdown-item" to="/login" onClick={handleLogout}>
              Logout
            </Link>
          </div>
        </li>
        {/* /User Profile */}
      </ul>
      {/* /Header Menu */}
    </div>
  );
};

export default Header;
