import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../../../../AuthContext';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from 'antd';

const ChatMessage = ({ message, senderId }) => {
  // Determine if the current user is the sender of the message
  const isSender = message.sender_id === senderId;

  // Assign the correct class based on whether the user is the sender or receiver
  const chatPositionClass = isSender ? 'chat-right' : 'chat-left';

  return (
    <div className={`chat ${chatPositionClass}`}>
      <div className="chat-body">
        <div className="chat-bubble">
          <div className="chat-content">
            <Tooltip title={moment(message.created_at).format('LLLL')} placement="top">
              <p>{message.message_text || 'No message content available'}</p>
            </Tooltip>
            <span className="chat-time">{moment(message.created_at).fromNow()}</span>
          </div>
          <div className="chat-action-btns">
            {/* Add any additional action buttons here */}
          </div>
        </div>
      </div>
    </div>
  );
};

ChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
  senderId: PropTypes.number.isRequired,
};

const ChatContent = ({ receiverId }) => {
  const { authState } = useAuth();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const senderId = authState.user_id;
  

  useEffect(() => {
    const fetchMessages = async () => {
      if (!receiverId || !senderId) {
          console.error('Receiver Id or Sender Id is undefined');
          return;
      }
      try {
          const apiUrl = `https://wd79p.com/backend/public/api/chat-user/${receiverId}/${senderId}`;
          const response = await axios.get(apiUrl);
          const allMessages = response.data.ConvoData;
          setMessages(allMessages);
          setLoading(false);
      } catch (error) {
          console.error('Error fetching messages:', error);
          setError('Failed to fetch messages');
          setMessages([]);
          setLoading(false);
      }
  };
  

    if (senderId && receiverId) {
        fetchMessages();
        const interval = setInterval(fetchMessages, 5000);
        return () => clearInterval(interval); 
    }
}, [senderId, receiverId]);


  if (loading) {
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}><Empty /></div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <div className="chats">
        {messages.length > 0 ? (
          messages.map((message) => (
            <ChatMessage key={message.id} message={message} senderId={senderId} />
          ))
        ) : (
          <div><Empty /></div>
        )}
      </div>
    </div>
  );
};

export default ChatContent;
